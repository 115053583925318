import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			redirect: "/dashboard",
			component: () => import("@/view/layout/Layout"),
			children: [
				{
					path: "dashboard",
					name: "dashboard",
					component: () => import("@/view/pages/Dashboard"),
					meta: { menuActive: "dashboard" },
				},
				{
					path: "calendar",
					name: "calendar",
					component: () => import("@/view/module/Calendar"),
					meta: { menuActive: "calendar" },
				},
				{
					path: "invoice",
					name: "invoice",
					component: () => import("@/view/module/invoice/Invoice"),
					meta: { menuActive: "invoice" },
				},
				{
					path: "invoice/create",
					name: "invoice-create",
					component: () => import("@/view/module/invoice/Create-Invoice"),
					meta: { menuActive: "invoice" },
				},
				{
					path: "invoice/update/:uuid",
					name: "invoice-update",
					component: () => import("@/view/module/invoice/Create-Invoice"),
					meta: { menuActive: "invoice" },
				},
				{
					path: "payment",
					name: "payment",
					component: () => import("@/view/module/payment/Payment"),
					meta: { menuActive: "payment" },
				},
				{
					path: "payment/create",
					name: "payment-create",
					component: () => import("@/view/module/payment/Create-Payment"),
					meta: { menuActive: "payment" },
				},
				{
					path: "payment/update/:uuid",
					name: "payment-update",
					component: () => import("@/view/module/payment/Create-Payment"),
					meta: { menuActive: "payment" },
				},
				{
					path: "contacts",
					name: "contacts",
					component: () => import("@/view/module/contacts/Contacts"),
					children: [
						{
							path: "customer",
							name: "customer",
							component: () => import("@/view/module/contacts/customer/Customer"),
							meta: { menuActive: "customer" },
						},
						{
							path: "customer/create",
							name: "customer-create",
							component: () => import("@/view/module/contacts/customer/Create-Customer"),
							meta: { menuActive: "customer" },
						},
						{
							path: "customer/:uuid/update",
							name: "customer-update",
							component: () => import("@/view/module/contacts/customer/Create-Customer"),
							meta: { menuActive: "customer" },
						},
						{
							path: "customer/:uuid",
							name: "customer-detail",
							component: () => import("@/view/module/contacts/customer/Detail-Customer"),
							meta: { menuActive: "customer" },
						},
						{
							path: "property",
							name: "property",
							component: () => import("@/view/module/contacts/property/Property"),
						},
						{
							path: "property/create",
							name: "property-create",
							component: () => import("@/view/module/contacts/property/Create-Property"),
						},
						{
							path: "property/:uuid/update",
							name: "property-update",
							component: () => import("@/view/module/contacts/property/Create-Property"),
						},
						{
							path: "property/:uuid",
							name: "property-detail",
							component: () => import("@/view/module/contacts/property/Detail-Property"),
						},
						{
							path: "vendor",
							name: "vendor",
							component: () => import("@/view/module/contacts/vendor/Vendor"),
						},
						{
							path: "vendor/create",
							name: "vendor-create",
							component: () => import("@/view/module/contacts/vendor/Create-Vendor"),
						},
						{
							path: "vendor/:uuid/update",
							name: "vendor-update",
							component: () => import("@/view/module/contacts/vendor/Create-Vendor"),
						},
						{
							path: "vendor/:uuid",
							name: "vendor-detail",
							component: () => import("@/view/module/contacts/vendor/Detail-Vendor"),
						},
					],
				},
				{
					path: "transaction",
					name: "transaction",
					component: () => import("@/view/module/Transaction"),
				},
				{
					path: "cart",
					name: "cart",
					component: () => import("@/view/module/cart/Cart"),
				},
				{
					path: "cart/create",
					name: "cart-create",
					component: () => import("@/view/module/cart/Create-Cart"),
				},
				{
					path: "cart/:uuid/update",
					name: "cart-update",
					component: () => import("@/view/module/cart/Create-Cart"),
				},
				{
					path: "cart/:uuid",
					name: "cart-detail",
					component: () => import("@/view/module/cart/Detail-Cart"),
				},
				{
					path: "documents",
					name: "documents",
					component: () => import("@/view/module/documents/Documents"),
				},
				{
					path: "orders",
					name: "orders",
					component: () => import("@/view/module/orders/Orders"),
				},
				{
					path: "order/:uuid",
					name: "order-detail",
					component: () => import("@/view/module/orders/Order-Details"),
				},
				{
					path: "member",
					name: "member",
					component: () => import("@/view/module/members/Member"),
					meta: { menuActive: "member" },
				},
				{
					path: "member/create",
					name: "member-create",
					component: () => import("@/view/module/members/Create-Member"),
					meta: { menuActive: "member" },
				},
				{
					path: "member/:uuid",
					name: "member-detail",
					component: () => import("@/view/module/members/Detail-Member"),
					meta: { menuActive: "member" },
				},
				{
					path: "member/:uuid/update",
					name: "member-update",
					component: () => import("@/view/module/members/Create-Member"),
					meta: { menuActive: "member" },
				},
				{
					path: "asset",
					name: "asset",
					component: () => import("@/view/module/assets/Asset"),
					meta: { menuActive: "asset" },
				},
				{
					path: "asset/create",
					name: "asset-create",
					component: () => import("@/view/module/assets/Create-Asset"),
					meta: { menuActive: "asset" },
				},
				{
					path: "asset/:uuid",
					name: "asset-detail",
					component: () => import("@/view/module/assets/Detail-Asset"),
					meta: { menuActive: "asset" },
				},
				{
					path: "asset/:uuid/update",
					name: "asset-update",
					component: () => import("@/view/module/assets/Create-Asset"),
					meta: { menuActive: "asset" },
				},
				{
					path: "asset-stock",
					name: "asset-stock",
					component: () => import("@/view/module/asset-stocks/Asset-Stock"),
				},
				{
					path: "asset-stock/create",
					name: "asset-stock-create",
					component: () => import("@/view/module/asset-stocks/Create-Asset-Stock"),
				},
				{
					path: "asset-stock/:uuid",
					name: "asset-stock-detail",
					component: () => import("@/view/module/asset-stocks/Detail-Asset-Stock"),
				},
				{
					path: "asset-stock/:uuid/update",
					name: "asset-stock-update",
					component: () => import("@/view/module/asset-stocks/Create-Asset-Stock"),
				},
				{
					path: "inventory",
					name: "inventory",
					component: () => import("@/view/module/inventories/Inventory"),
				},
				{
					path: "inventory/create",
					name: "inventory-create",
					component: () => import("@/view/module/inventories/Create-Inventory"),
				},
				{
					path: "inventory/:uuid",
					name: "inventory-detail",
					component: () => import("@/view/module/inventories/Detail-Inventory"),
				},
				{
					path: "inventory/:uuid/update",
					name: "inventory-update",
					component: () => import("@/view/module/inventories/Create-Inventory"),
				},
				{
					path: "location",
					name: "location",
					component: () => import("@/view/module/locations/Location"),
				},
				{
					path: "location/create",
					name: "location-create",
					component: () => import("@/view/module/locations/Create-Location"),
				},
				{
					path: "location/:uuid",
					name: "location-detail",
					component: () => import("@/view/module/locations/Detail-Location"),
				},
				{
					path: "location/:uuid/update",
					name: "location-update",
					component: () => import("@/view/module/locations/Create-Location"),
				},
				{
					path: "services",
					name: "service",
					component: () => import("@/view/module/services/Services"),
					meta: { menuActive: "service" },
				},
				{
					path: "checked-out",
					name: "checkedout",
					component: () => import("@/view/module/checked-out/Checked-Out"),
				},
				{
					path: "check-in",
					name: "checkin",
					component: () => import("@/view/module/checked-in/Checked-In"),
				},
				{
					path: "retire",
					name: "retire",
					component: () => import("@/view/module/retire/Retire"),
				},
				{
					path: "reservations",
					name: "reservations",
					component: () => import("@/view/module/reservation/Reservation"),
				},
				{
					path: "group",
					name: "group",
					component: () => import("@/view/module/groups/Group"),
				},
				{
					path: "group/create",
					name: "group-create",
					component: () => import("@/view/module/groups/Create-Group"),
				},
				{
					path: "group/:uuid",
					name: "group-detail",
					component: () => import("@/view/module/groups/Detail-Group"),
				},
				{
					path: "group/:uuid/update",
					name: "group-update",
					component: () => import("@/view/module/groups/Create-Group"),
				},
				{
					path: "project",
					name: "project",
					component: () => import("@/view/module/projects/Project"),
				},
				{
					path: "project/create",
					name: "project-create",
					component: () => import("@/view/module/projects/Create-Project"),
				},
				{
					path: "project/:uuid",
					name: "project-detail",
					component: () => import("@/view/module/projects/Detail-Project"),
				},
				{
					path: "project/:uuid/update",
					name: "project-update",
					component: () => import("@/view/module/projects/Create-Project"),
				},
				{
					path: "report",
					name: "report",
					component: () => import("@/view/module/reports/Report"),
				},
				{
					path: "report/asset",
					name: "asset-report",
					component: () => import("@/view/module/reports/Asset-Report"),
				},
				{
					path: "report/assetStock",
					name: "assetStock-report",
					component: () => import("@/view/module/reports/AssetStock-Report"),
				},
				{
					path: "report/asset-by-project",
					name: "project-report",
					component: () => import("@/view/module/reports/Project-report"),
				},
				{
					path: "report/perishables",
					name: "perishables-report",
					component: () => import("@/view/module/reports/Perishables-Report"),
				},
				{
					path: "setting",
					name: "setting",
					component: () => import("@/view/module/setting/Setting.vue"),
				},
				{
					path: "users",
					name: "users",
					component: () => import("@/view/module/user/User.vue"),
					meta: {
						requiresAuth: true,
						title: "User",
					},
				},
				{
					path: "user/create",
					name: "user-create",
					component: () => import("@/view/module/user/Create-User"),
				},
				{
					path: "user/:uuid",
					name: "user-detail",
					component: () => import("@/view/module/user/Detail-User"),
				},
				{
					path: "user/:uuid/update",
					name: "user-update",
					component: () => import("@/view/module/user/Create-User"),
				},

				{
					path: "checkout",
					name: "checkout",
					component: () => import("@/view/module/Checkout.vue"),
				},
				{
					path: "email-template/update/:id",
					name: "email-template-update",
					component: () => import("@/view/module/setting/email/EmailTemplateUpdate"),
				},
				{
					path: "role/create",
					name: "role.create",
					component: () => import("@/view/module/setting/rolePermission/CreateRole"),
					meta: {
						requiresAuth: true,
						title: "Create Role",
					},
				},
				{
					path: "role/update/:id",
					name: "role.update",
					component: () => import("@/view/module/setting/rolePermission/CreateRole"),
					meta: {
						requiresAuth: true,
						title: "Update Role",
					},
				},
				{
					path: "activity",
					name: "activity",
					component: () => import("@/view/module/activity/Activity"),
					meta: { menuActive: "activity" },
				},
			],
		},
		{
			path: "/shortened-url/:url",
			name: "shortened.url",
			component: () => import("@/view/pages/Short-URL.vue"),
			meta: { requiresAuth: false, title: "Short URL" },
		},
		{
			path: "/checkout-approve/:uuid",
			name: "checkout-approve",
			component: () => import("@/view/module/approve/CheckoutSignatureLink.vue"),
		},
		{
			path: "/checkin-approve/:uuid",
			name: "checkin-approve",
			component: () => import("@/view/module/approve/CheckinSignatureLink.vue"),
		},
		{
			path: "/retired-approve/:uuid",
			name: "retired-approve",
			component: () => import("@/view/module/approve/RetiredSignatureLink.vue"),
		},
		{
			path: "/checkout-approve",
			name: "checkout-approve-mail",
			component: () => import("@/view/module/approve/CheckoutApproveMail.vue"),
		},
		{
			path: "/checkin-approve",
			name: "checkin-approve-mail",
			component: () => import("@/view/module/approve/CheckinApproveMail.vue"),
		},
		{
			path: "/retired-approve",
			name: "retired-approve-mail",
			component: () => import("@/view/module/approve/RetiredApproveMail.vue"),
		},
		{
			path: "/custom-error",
			name: "error",
			component: () => import("@/view/pages/error/Error"),
			children: [
				{
					path: "error-1",
					name: "error-1",
					component: () => import("@/view/pages/error/Error-1"),
				},
			],
		},
		{
			name: "authenticate",
			path: "/",
			component: () => import("@/view/pages/auth/Authenticate"),
			children: [
				{
					path: "login",
					name: "login",
					component: () => import("@/view/pages/auth/Login"),
				},
			],
		},
		{
			path: "*",
			redirect: "/404",
		},
		{
			// the 404 route, when none of the above matches
			path: "/404",
			name: "404",
			component: () => import("@/view/pages/error/Error-1"),
		},
	],
});
